const makeAuthRequest = async (url, method = "GET", body = null) => {
  let response = await fetch(url, {
    method: method,
    body: body,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
  let data = await response.json();
  return data;
};

export default makeAuthRequest;
