import React, { useState ,useEffect} from "react";
import makeAuthRequest from "../../services/Requests";
import Platform from "./platform"
import AttachmentSelection from "./AttachmentSelection"

import {getArticleFromUrl,formatTextForWa,addAttachmentIconToText, isKore} from "../../utils"
const Whatsapp = (props) => {
  const [loadedArticle, setLoadedArticle] = useState(props.article);
  const [checkedForUrlArticle, setCheckedForUrlArticle] = useState(false)

  const reCalcInitalSelectedAttachment = () => loadedArticle?.attachment || null
  const [selectedAttachment, setSelectedAttachment] = useState(reCalcInitalSelectedAttachment());

  const isSelected = (platform) => loadedArticle?.postedTo?.[platform];
  const getArticleText = (platform) => {
    if (!loadedArticle) return ""
    return loadedArticle.postedTo?.[platform]?.text || loadedArticle.text
  };
  const reCalcInitalPostToState = () => {
    return{
    whatsapp: {
      selected: !isSelected("whatsapp"),
      text: getArticleText("whatsapp") ? formatTextForWa(getArticleText("whatsapp")): "",
      scheduleDate:  null,
    },
    facebook: {
      selected: !isKore ? (loadedArticle? !isSelected("facebook") : false) : false,
      text: getArticleText("facebook"),
      scheduleDate: null,
    }
  }}
  const [postTo, setPostTo] = useState(reCalcInitalPostToState());

  const checkForArticleInUrl = async () => {
      try {
        const article = await getArticleFromUrl()
        if (!article) return
        console.log("article from url",article)
        setLoadedArticle(article)

      } catch (error) {
        alert("שגיאה בטעינת הכתבה: "+error.message)
      }
  }
  useEffect(() => {
    if (!checkedForUrlArticle && !props.article){
      setCheckedForUrlArticle(true)
      checkForArticleInUrl()
    }
    setPostTo(reCalcInitalPostToState())
    setSelectedAttachment(reCalcInitalSelectedAttachment())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[loadedArticle])
  useEffect(() => {

    const regex = /(?:\uD83C[\uDF00-\uDFFF] )|(?:\uD83D[\uDC00-\uDDFF] )/g;

    const whatsappValue = postTo.whatsapp.text?.replace(regex,'') || ""
    const facebookValue = postTo.facebook.text?.replace(regex,'') || ""
    handleText({target: {name: 'whatsapp', value: whatsappValue}})
    handleText({target: {name: 'facebook', value: facebookValue}})

    if (selectedAttachment == null) return
    handleText({target: {name: 'whatsapp', value: addAttachmentIconToText(whatsappValue, selectedAttachment,true)}})
    handleText({target: {name: 'facebook', value: addAttachmentIconToText(facebookValue, selectedAttachment)}})

  },[selectedAttachment])

  const [isLoading, setIsLoading] = useState(false);
  const postArticle = async () => {

    if (!postTo.whatsapp.selected && !postTo.facebook.selected) {
      alert("יש לבחור באפשרות אחת לפחות");
      return;
    }
    for (const key in postTo) {
      if (!postTo[key].text && postTo[key].selected) {
        alert("התוכן אינו יכול להיות ריק");
        return;
      }
    }

    const displayError = (e) => {
      alert("אירעה שגיאה בעת פרסום הכתבה. יתכן שכתבה זו פורסמה כבר. שגיאה: "+e);
    };

    const handleOk = (resp) => {
      //if (props.article) 
      props.change.updateArticle(resp.result);
      props.change.closePopup();

    };
    const filterPostTo = (postTo) => {
      const selected =  Object.fromEntries(Object.entries(postTo).filter(([k, v]) => v.selected))
      for (const key in selected){
        const platform = selected[key]
        selected[key]= { 
          scheduledFor: platform.scheduleDate?.getTime() ,
          text: platform.text
        }
      }
      return selected
    }
    setIsLoading(true);
    try {
      var result = await makeAuthRequest(
        "/api/articles/post",
        "POST",
        JSON.stringify({
          articleId: loadedArticle?.id,
          websiteId: loadedArticle?.websiteId,
          postTo: filterPostTo(postTo),
          attachment: selectedAttachment || null
        })
      );
    } catch(e) {
      setIsLoading(false);
      displayError(e);
      return;
    }
    setIsLoading(false);
    if (!result.ok) {
      displayError(JSON.stringify(result));
      return;
    }
    handleOk(result);
  };

  const handlePostToChange = (e) => {
    const { name } = e.target;
    setPostTo((inputs) => ({
      ...inputs,
      [name]: { ...inputs[name], selected: !postTo[name].selected },
    }));
  };



  const handleScheduleDate = (date, platform) => {
    setPostTo((inputs) => ({
      ...inputs,
      [platform]: { ...inputs[platform], scheduleDate: date },
    }));
  };

  const handleText = (e) => {
    const { name, value } = e.target;
    setPostTo((inputs) => ({
      ...inputs,
      [name]: { ...inputs[name], text: value },
    }));
  };
  return (
    <div>
      <div className="header">
        <h1>שליחה לוואטסאפ</h1>
      </div>
      <div className="body">

      <AttachmentSelection initialValue={loadedArticle?.attachment || null} onSelectionChanged={(name) => {
          setSelectedAttachment(name)
        }}/>
        <Platform
          platformText=""
          disabled={!postTo.whatsapp.selected}
          platformValue="whatsapp"
          updateScheduleDate={handleScheduleDate}
          updateText={handleText}
          text={postTo.whatsapp.text}
          getArticleSchedule={postTo.whatsapp.scheduleDate}
        />
        {!isKore &&
        <>
      <div className="checkbox-label-container short-fb-checkbox-container">
            <input
              type="checkbox"
              checked={postTo.facebook.selected}
              disabled={isSelected("facebook")}
              onChange={handlePostToChange}
              name="facebook"
            />
            <label className="checkbox-label short-fb-label">שליחה לפייסבוק</label>
          </div>

        <Platform
          platformText="פייסבוק"
          disabled={!postTo.facebook.selected}
          updateScheduleDate={handleScheduleDate}
          updateText={handleText}
          text={postTo.facebook.text}
          platformValue="facebook"
          minimized={true}
          getArticleSchedule={postTo.facebook.scheduleDate}
        />
        </>
      }
        <div className="actions">
          <button disabled={isLoading} onClick={postArticle}>
            {isLoading ? <i className="fa fa-circle-o-notch fa-spin"></i> : "פרסום"}
          </button>
        </div>
      </div>
    </div>
  );
};


export default Whatsapp

//export default Post;
