import React, { useState,useEffect } from "react";
import { useParams } from "react-router";
import makeAuthRequest from "../../services/Requests";
import {getArticleFromUrl,addAttachmentIconToText, isKore} from "../../utils"
import AttachmentSelection from "./AttachmentSelection"

const Push = (props) => {
  const ERROR_IMAGE_URL = "https://i.imgur.com/nlNVhRs.png"
  const [loadedArticle, setLoadedArticle] = useState(props.article);


  const reCalcInitalSelectedAttachment = () => loadedArticle?.attachment || null
  const [selectedAttachment, setSelectedAttachment] = useState(reCalcInitalSelectedAttachment());

  const getInitialPushTo = ( ) => ({
    oneSignalMobile: isKore ? false : (!loadedArticle?.pushedTo.oneSignalMobile ? true : false),
    oneSignalDesktop: !loadedArticle?.pushedTo.oneSignalDesktop ? true : false,

  })

  const [pushTo,setPushTo] = useState(() => getInitialPushTo())
  const parseArticelText = () => {
    if (!loadedArticle) return {
      headTitle: "",
      title: "",
      link: "",
      image: "",
      imageCheckBox: true
    }
    const article = loadedArticle
    let text = article.text
    const link = text.split(/(https?:\/\/[^\s]+)/g)[1]
    if (link) text = text.replace(link,"")
    const parts = text.split(">").map(v => v.trim())
    
    const result = {
      headTitle: parts[0] || "",
      title:  parts[1] ?  parts[1] + ` ` : "",
      link: link || "",
      image: article.image || "",
      imageCheckBox: article.image !== null
    }
    if (result.headTitle != "" && result.title == ""){
      result.title = String(result.headTitle + ` `)
      result.headTitle = isKore ? "כל רגע": ""
    }
    return result
  }

  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(() => parseArticelText())
  
  const postArticle = async () => {


    if (!pushTo.oneSignalDesktop && !pushTo.oneSignalMobile) {
      return alert("יש לבחור לשלוח לפלטפורמה אחת לפחות");
    }

    if (!formValues.title) return alert("הכותרת היא שדה חובה")
    if (!formValues.link) return alert("קישור הכתבה הוא שדה חובה")

    if (formValues.imageCheckBox && !formValues.image){
      return alert("בחרת לשלוח תמונה, אבל השארת את השדה ריק. אם אינך רוצה לשלוח תמונה, יש להוריד את הסימון.")
    }
    

    const displayError = (e) => {
      alert("אירעה שגיאה בעת שליחת הפוש. יתכן שכתבה זו נשלחה כבר. שגיאה: "+e);
    };

    const handleOk = (resp) => {
      //if (props.article) 
      props.change.updateArticle(resp.result);
      props.change.closePopup();

    };


    const pushToPlatforms = Object.keys(pushTo).filter(k => pushTo[k])
    setIsLoading(true);
    try {
      var result = await makeAuthRequest(
        "/api/articles/push",
        "POST",
        JSON.stringify({
          websiteId: loadedArticle?.websiteId,
          articleId: loadedArticle?.id,
          pushTo: pushToPlatforms,
          pushInfo: {
            headTitle: formValues.headTitle,
            title: formValues.title,
            url: formValues.link,
            imageUrl: formValues.imageCheckBox ? formValues.image : undefined
          },
          attachment: selectedAttachment || null
        })
      );
    } catch(e) {
      setIsLoading(false);
      displayError(e);
      return;
    }
    setIsLoading(false);
    if (!result.ok) {
      displayError(JSON.stringify(result));
      return;
    }
    handleOk(result);
  };


  const [checkedForUrlArticle, setCheckedForUrlArticle] = useState(false)
  let { openPlatform } = useParams();

  const checkForArticleInUrl = async () => {
    try {
      const article = await getArticleFromUrl()
      if (!article) return
      console.log("article from url",article)
      if (openPlatform === "push-mobile"){
        article.pushedTo.oneSignalDesktop = true
      }
      else if (openPlatform === "push-desktop"){
        article.pushedTo.oneSignalMobile = true
      }
      //fake mark as sent to make sure that user canot select if coming from url
      

      setLoadedArticle(article)

    } catch (error) {
      alert("שגיאה בטעינת הכתבה: "+error.message)
    }
}

useEffect(() => {
  if (!checkedForUrlArticle && !props.article){
    setCheckedForUrlArticle(true)
    checkForArticleInUrl()
  }
  setFormValues(parseArticelText())
  setPushTo(() => getInitialPushTo())
  setSelectedAttachment(reCalcInitalSelectedAttachment())
    // eslint-disable-next-line react-hooks/exhaustive-deps
},[loadedArticle])
useEffect(() => {

  const regex = /(?:\uD83C[\uDF00-\uDFFF] )|(?:\uD83D[\uDC00-\uDDFF] )/g;

  const value = formValues.title?.replace(regex,'') || ""
  handleForm({target: {name: 'title', value: value}})

  if (selectedAttachment == null) return
  handleForm({target: {name: 'title', value: addAttachmentIconToText(value, selectedAttachment)}})

},[selectedAttachment])

  const handleForm = (e) => {
    const { name, value } = e.target;
     setFormValues((inputs) => ({
       ...inputs,
       [name]: value,
     }));
  };

  const handlePushTo = (e) => {
    const { name } = e.target;
    setPushTo((pushTo) => ({
      ...pushTo,
      [name]: !pushTo[name],
    }));
  }
  const isPlatformHidden = (p) => {
    if (p === "push-desktop" && openPlatform === "push-desktop") return true
    if (p === "push-mobile" && openPlatform === "push-mobile") return true
    return false
  }
  return (
    <div>
      <div className="header">
        <h1>שליחת פוש</h1>
      </div>
      <div className="body">


      <div className="post-to">
          <h3>פלטפורמות</h3>
          <div className="checkbox-container">
          {!isKore &&  (<div className="checkbox-label-container">
            <input
              type="checkbox"
              name="oneSignalMobile"
              onChange={handlePushTo}
              checked={pushTo.oneSignalMobile}
              hidden={isPlatformHidden("push-desktop")}
              disabled={loadedArticle?.pushedTo?.oneSignalMobile}

            />
            <label hidden={isPlatformHidden("push-desktop")} className="checkbox-label">אפליקציה</label>
          </div>)}
          <div className="checkbox-label-container">
            <input
              type="checkbox"
              name="oneSignalDesktop"
              onChange={handlePushTo}
              checked={pushTo.oneSignalDesktop}
              hidden={isPlatformHidden("push-mobile")}
              disabled={loadedArticle?.pushedTo?.oneSignalDesktop}

              
            />
            <label hidden={isPlatformHidden("push-mobile")} className="checkbox-label">ווב</label>

            </div>
          </div>
        </div>

        <AttachmentSelection initialValue={loadedArticle?.attachment || null} onSelectionChanged={(name) => {
          setSelectedAttachment(name)
        }}/>

        <div className="platform">
          <div className="push">
           
           <div className="section">
           <label>כותרת גג</label>
           <input type="search" onChange={handleForm} value={formValues.headTitle} name="headTitle" placeholder="כותרת גג"/>
           </div>

           <div className="section">
           <label>כותרת</label>
           <input type="search" onChange={handleForm} value={formValues.title} name="title" placeholder="כותרת"/>
           </div>

           <div className="section">
           <label>קישור לכתבה</label>
           <input type="search" onChange={handleForm} value={formValues.link} name="link" placeholder="קישור לכתבה"/>
           </div>

           <div className="section">
           <div className="checkbox-label-container">
            <input
              type="checkbox"
              defaultChecked={formValues.imageCheckBox}
              onChange={() => handleForm({target: {name: "imageCheckBox", value: !formValues.imageCheckBox}})}
            />
            <label className="checkbox-label">תמונה</label>
          </div>

           <div className={formValues.imageCheckBox ? "section" : "section disabled"}>
           <input type="search" onChange={handleForm} value={formValues.image} name="image" placeholder="תמונה"></input>
           </div>
            { formValues.imageCheckBox &&
           <div className="image-preview">
             <h4>תצוגה מקדימה</h4>
             <img src={formValues.image} alt="" onError={(e)=>{ if (e.target.src !== ERROR_IMAGE_URL){
                 e.target.src=ERROR_IMAGE_URL;}}}  />
           </div>
            }
           </div>
        </div>
        </div>

        <div className="actions">
          <button disabled={isLoading} onClick={postArticle}>
            {isLoading ? <i className="fa fa-circle-o-notch fa-spin"></i> : "שליחה"}
          </button>
        </div>
      </div>
    </div>
  );
};



export default Push;
