import React from "react";
import "./styles/App.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from "./components/login";
import Main from "./components/main";
import decode from "jwt-decode";
const checkAuth = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    return false;
  }

  try {
    const { exp } = decode(token);
    if (exp < new Date().getTime() / 1000) {
      return false;
    }
  } catch (e) {
    return false;
  }

  return true;
};

const AuthRote = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuth() ? <Component {...props} /> : <Redirect to={{ pathname: "login" }} />
    }
  />
);

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" render={(props) => <Login {...props} />} />
          <AuthRote exact path="/:openPlatform?" component={Main} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
