import makeAuthRequest from "../services/Requests";

export const articleFromId = async (id) => {
  const res = await makeAuthRequest(`/api/articles/construct-text/${id}`);
  if (!res.ok) throw Error(res.message);
  const result = res.result;
  if (result.dbRecord) return result.dbRecord;
  return {
    //fake db data
    websiteId: id,
    text: result.constructdText,
    image: result.fullInfo?.imageUrl,
    postedTo: {},
    pushedTo: {},
  };
};

export const isKore = window.location.hostname.includes("kore.co.il");
export const appName = isKore ? "כל רגע" : "בחדרי חרדים";

export const attachmentNameToIcon = (name, strip) => {
  switch (name) {
    case "video":
      return "🎥 ";
    case "gallery":
      return "📸 ";
    case "audio":
      return "🎙 ";
    default:
      return "";
  }
};
export const addAttachmentIconToText = (text, attachmentName = "", whatsapp = false) => {
  const seperator = whatsapp ? "|" : ">";
  const titles = text.split(` ${seperator} `);
  const lastTitle = titles[titles.length - 1];
  if (!lastTitle || titles.length - 1 === 0) {
    return text + `${attachmentNameToIcon(attachmentName)}`;
  }
  return text.replace(
    ` ${seperator} ${lastTitle}`,
    ` ${attachmentNameToIcon(attachmentName)}${seperator} ${lastTitle}`
  );
};

export const formatTextForWa = (text, attachmentName = "") => {
  const titles = text.split(" > ");
  if (titles.length >= 2) {
    text = text.replace(titles[0], `*${titles[0]}*`);
  }
  const link = text.split(/(https?:\/\/[^\s]+)/g)[1];
  if (!link) return text + attachmentNameToIcon(attachmentName);
  text = text.replace(
    `> ${link}`,
    `${attachmentNameToIcon(attachmentName)}| *${appName}* >> ${link}`
  );
  return text;
};

export const getArticleFromUrl = async () => {
  const query = new URLSearchParams(window.location.search);
  const articleId = query.get("articleid");
  if (!articleId) return;
  try {
    return await articleFromId(articleId);
  } catch (error) {
    throw error;
  }
};
