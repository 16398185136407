import React, { useState } from "react";
import Popup from "../popup";
import Post from "../popup/post";
import Push from "../popup/push";
import moment from 'moment'
import Delete from "../popup/delete";
import 'moment/locale/he';
import Whatsapp from "../popup/whatsapp";
import { useHistory } from "react-router-dom";
import {isKore} from "../../utils"

moment.locale('he', {
  longDateFormat : {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "MM/DD/YY",
    LL: "MMMM Do yy",
    LLL: "MMMM Do YYYY LT",
    LLLL: "dddd, MMMM Do YYYY LT"
},
  calendar : {
      lastDay : '[אתמול] LT',
      sameDay : 'LT',
      nextDay : '[מחר] LT',
      lastWeek : 'dddd [האחרון] LT',
      nextWeek : 'L LT',
      sameElse : 'L LT'
  }
});

const Article = (props) => {
  const history = useHistory();

  const [popupContent, setPopupContent] = useState(null);
  const dismissPopup = () => {
    history.push("/");
    setPopupContent(null);
  };

  const passToChildren = {
    removeFromArticles: props.removeFromArticles,
    closePopup: dismissPopup,
    updateArticle: props.updateArticle,
  };
  const formatTime = (timeStamp) => moment(new Date(timeStamp)).calendar()
     
  

  const getRoundedTimestamp = (minutes, timeStamp) => {
    if (!timeStamp) return
    const ms = 1000 * 60 * minutes; // convert minutes to ms
    return  Math.round(timeStamp / ms) * ms;
  }
  const ROUND_TO_NEAR_MIN = 1.5
  const allEqualArray = arr => {
    arr = arr.filter(f => f)
    return arr.every( v => getRoundedTimestamp(ROUND_TO_NEAR_MIN,v) === getRoundedTimestamp(ROUND_TO_NEAR_MIN,arr[0]) )
  } 

  //THIS NEEDS TO BE REFACTORED!! BAD(fast) CODE  :)
  const renderPostStatus = () => {
    const getPostedToText = () => {
    if (!props.postedTo && !props.pushedTo) return "";     
    const notSameTime = !allEqualArray([props.postedTo.facebook?.postedAt, props.postedTo.twitter?.postedAt, props.postedTo.whatsapp?.postedAt])
    const postedTo = Object.keys(props.postedTo).filter(name => !(props.userPermission === "push" && name === "twitter") && !(props.userPermission === "post" && name=== "whatsapp") ).map((name) => {
      const p = props.postedTo[name]
        p.postedAt = getRoundedTimestamp(ROUND_TO_NEAR_MIN,p.postedAt)
        switch (name) {
          case "facebook":
            return "פייסבוק" + (notSameTime ? ` (${formatTime(p.postedAt)})` : "");
          case "twitter":
            return "טוויטר" + (notSameTime ? ` (${formatTime(p.postedAt )})` : "");
          case "whatsapp":
              return "וואטסאפ" + (notSameTime ? ` (${formatTime(p.postedAt )})` : "");
          default:
            return ""
        }
      });
      const result = postedTo.join(", ")
      if (!result) return ""
      return `פורסם ב${result}` + (!notSameTime ? ` (${formatTime(props.postedTo.facebook?.postedAt || props.postedTo.twitter?.postedAt || props.postedTo.whatsapp?.postedAt) })`: "");
  }
      let text = ""
      const resultText = getPostedToText()
      text += resultText


    if ((props.userPermission === "push" || props.userPermission === "admin") && props.pushedTo.oneSignalMobile){
      if (text !== "") text += ` | `
      // eslint-disable-next-line
      text += "פוש נשלח" + ` (${formatTime(getRoundedTimestamp(ROUND_TO_NEAR_MIN,props.pushedTo.oneSignalMobile.pushedAt))})` ;
    }else if (isKore && (props.userPermission === "push" || props.userPermission === "admin") && props.pushedTo.oneSignalDesktop){
      if (text !== "") text += ` | `
      // eslint-disable-next-line
      text += "פוש נשלח" + ` (${formatTime(getRoundedTimestamp(ROUND_TO_NEAR_MIN,props.pushedTo.oneSignalDesktop.pushedAt))})` ;
    }

   return text
  };

  const renderPopupContent = () => {
    switch (popupContent) {
      case 0:
        return (
          <Popup closePopup={dismissPopup}>
            <Post change={passToChildren} article={props} />
          </Popup>
        );
      case 1:
        return (
          <Popup closePopup={dismissPopup}>
            <Delete change={passToChildren} article={props} />
          </Popup>
        );
      case 2:
          return (
            <Popup closePopup={dismissPopup}>
              <Push change={passToChildren} article={props} />
            </Popup>
          );
      case 3:
          return (
            <Popup closePopup={dismissPopup}>
              <Whatsapp change={passToChildren} article={props} />
            </Popup>
          );
      default:
        return null;
    }
  };
  return (
    <div className="article">
      <div className="container">
        <div className="text">
          <p>
            {props.text.split(/(https?:\/\/[^\s]+)/g).map((part, index) =>
              index % 2 === 0 ? (
                part
              ) : (
                <a href={part} key={index}>
                  {part}
                </a>
              )
            )}
          </p>
          <h4 className="status">{renderPostStatus()}</h4>
        </div>
        <div className="actions">
              {((props.userPermission === "post" || props.userPermission === "admin") &&  props.displayBy !=="2") ? (<button
            onClick={!( props.postedTo.facebook &&  props.postedTo.twitter)  ? () => setPopupContent(0) : () => null}
            disabled={( props.postedTo.facebook &&  props.postedTo.twitter)}
          >
            <div><i className="fa fa-facebook"/>  <i className="fa fa-twitter"/></div> 
          </button>): null}
          {((props.userPermission === "push" || props.userPermission === "admin") && props.displayBy !=="2") ? (<button
            onClick={() => setPopupContent(2)}
            disabled={isKore ? props.pushedTo.oneSignalDesktop : (props.pushedTo.oneSignalMobile && props.pushedTo.oneSignalDesktop)}
          >
            <i className="fa fa-bell"/>
          </button>): null}
          {((props.userPermission === "push" || props.userPermission === "admin") && props.displayBy !=="2") ? (<button
            onClick={() => setPopupContent(3)}
            disabled={props.postedTo.facebook && props.postedTo.whatsapp}
          >
            <i className="fa fa-whatsapp" />
          </button>): null}

          { props.displayBy ==="2" ? (<button
            onClick={() => setPopupContent(1)}
          >
            <i className="fa fa-trash"/>
          </button>): null}
        </div>
      </div>
      {renderPopupContent()}
    </div>
  );
};

export default Article;
