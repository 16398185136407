import React, { useState } from "react";
import makeAuthRequest from "../../services/Requests";

const Delete = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const deletePost = async () => {
    setIsLoading(true);
    const displayError = (e) => {
      alert("אירעה שגיאה בעת מחיקת הכתבה. יתכן שכתבה זו נמחקה כבר :",e);
    };

    const handleOK = (resp) => {
      props.change.closePopup();
      props.change.removeFromArticles(props.article.id);
    };

    try {
      const result = await makeAuthRequest(
        "/api/articles/",
        "DELETE",
        JSON.stringify({
          articleId: props.article.id,
        })
      );
      if (result.ok) {
        handleOK(result);
        return
      }
        displayError(result);
      
    } catch (e) {
      displayError(e);
    }
  };

  return (
    <div>
      <div className="header">
        <h1>מחיקה</h1>
      </div>
      <div className="body">
        <h4 className="delete-title">האם אתה בטוח שברצונך למחוק את הכתבה מהרשימה לכל המשתמשים?</h4>

        <div className="text-content">{props.article.text}</div>
        <div className="actions">
          <button disabled={isLoading} onClick={deletePost}>
            {isLoading ? <i className="fa fa-circle-o-notch fa-spin"></i> : "מחיקה"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Delete;
