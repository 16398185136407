import React, { useEffect, useState } from "react";

const AttachmentSelection = (props) => {
  const [selected, setSelected] = useState(props.initialValue | null);

    useEffect(() => {
        setSelected(props.initialValue || null)
    },[props.initialValue])
    const handleSelectionChange = (e) => {
        let { name } = e.target
        if (name === selected) name = null

        setSelected(name)
        props.onSelectionChanged && props.onSelectionChanged(name)
    }

  return (
    <div className="post-to">
        <h3>אייקון</h3>
        <div className="media-options">
        <div className="checkbox-label-container">
            <input
              type="checkbox"
              checked={selected === "video"}
              name="video"
              onChange={handleSelectionChange}
            />
            <label className="checkbox-label">וידאו 🎥</label>
       </div>
       <div className="checkbox-label-container">
            <input
              type="checkbox"
              checked={selected === "gallery"}
              name="gallery"
              onChange={handleSelectionChange}
            />
            <label className="checkbox-label">גלריה 📸</label>
       </div>        <div className="checkbox-label-container">
            <input
              type="checkbox"
              checked={selected === "audio"}
              name="audio"
              onChange={handleSelectionChange}
            />
            <label className="checkbox-label">שמע 🎙️</label>
       </div>
       </div>
    </div>
  );
};

export default AttachmentSelection;
