import React, { useState ,useEffect} from "react";
import makeAuthRequest from "../../services/Requests";
import Platform from "./platform"
import AttachmentSelection from "./AttachmentSelection"
import {getArticleFromUrl,addAttachmentIconToText} from "../../utils"
import { useParams } from "react-router";
const Post = (props) => {
  let { openPlatform } = useParams();

  const [loadedArticle, setLoadedArticle] = useState(props.article);
  const [checkedForUrlArticle, setCheckedForUrlArticle] = useState(false)
  const reCalcInitalSelectedAttachment = () => loadedArticle?.attachment || null
  const [selectedAttachment, setSelectedAttachment] = useState(reCalcInitalSelectedAttachment());
  const isSelected = (platform) => loadedArticle?.postedTo?.[platform];



  const reCalcInitalPostToState = () => {
    return{
    facebook: {
      selected: !isSelected("facebook"),
      text: getArticleText("facebook"),
      scheduleDate:  null,
    },
    twitter: {
      selected: !isSelected("twitter"),
      text: getArticleText("twitter"),
      scheduleDate: null,
    }
  }}


  const [postTo, setPostTo] = useState(reCalcInitalPostToState());



  function getArticleText (platform){
    //if (!loadedArticle) return ""
    const text =  loadedArticle?.postedTo?.[platform]?.text || loadedArticle?.text || ""
    if (!text || !selectedAttachment) return text
    return text
    //return addAttachmentIconToText(text, selectedAttachment)
  };


  const checkForArticleInUrl = async () => {
      try {
        const article = await getArticleFromUrl()
        if (!article) return
        console.log("article from url",article)
        if (openPlatform === "facebook"){
          article.postedTo.twitter = true
        }
        else if (openPlatform === "twitter"){
          article.postedTo.facebook = true
        }
        setLoadedArticle(article)

      } catch (error) {
        alert("שגיאה בטעינת הכתבה: "+error.message)
      }
  }
  useEffect(() => {
    if (!checkedForUrlArticle && !props.article){
      setCheckedForUrlArticle(true)
      checkForArticleInUrl()
    }
    setPostTo(reCalcInitalPostToState())
    setSelectedAttachment(reCalcInitalSelectedAttachment())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[loadedArticle])
  useEffect(() => {

    const regex = /(?:\uD83C[\uDF00-\uDFFF] )|(?:\uD83D[\uDC00-\uDDFF] )/g;
    const twitterValue = postTo.twitter.text?.replace(regex,'') || ""
    const facebookValue = postTo.facebook.text?.replace(regex,'') || ""
    handleText({target: {name: 'twitter', value: twitterValue}})
    handleText({target: {name: 'facebook', value: facebookValue}})

    if (selectedAttachment == null) return
    handleText({target: {name: 'twitter', value: addAttachmentIconToText(twitterValue, selectedAttachment)}})
    handleText({target: {name: 'facebook', value: addAttachmentIconToText(facebookValue, selectedAttachment)}})

  },[selectedAttachment])

  const [isLoading, setIsLoading] = useState(false);
  const postArticle = async () => {

    if (!postTo.facebook.selected && !postTo.twitter.selected) {
      alert("יש לבחור באפשרות שליחה אחת לפחות");
      return;
    }
    for (const key in postTo) {
      if (!postTo[key].text && postTo[key].selected) {
        alert("התוכן אינו יכול להיות ריק");
        return;
      }
    }

    const displayError = (e) => {
      alert("אירעה שגיאה בעת פרסום הכתבה. יתכן שכתבה זו פורסמה כבר. שגיאה: "+e);
    };

    const handleOk = (resp) => {
      //zif (props.article) 
      props.change.updateArticle(resp.result);
      props.change.closePopup();

    };
    const filterPostTo = (postTo) => {
      const selected =  Object.fromEntries(Object.entries(postTo).filter(([k, v]) => v.selected))
      for (const key in selected){
        const platform = selected[key]
        selected[key]= { 
          scheduledFor: platform.scheduleDate?.getTime() ,
          text: platform.text
        }
      }
      return selected
    }
    setIsLoading(true);
    try {
      var result = await makeAuthRequest(
        "/api/articles/post",
        "POST",
        JSON.stringify({
          articleId: loadedArticle?.id,
          websiteId: loadedArticle?.websiteId,
          postTo: filterPostTo(postTo),
          attachment: selectedAttachment || null
        })
      );
    } catch(e) {
      setIsLoading(false);
      displayError(e);
      return;
    }
    setIsLoading(false);
    if (!result.ok) {
      displayError(JSON.stringify(result));
      return;
    }
    handleOk(result);
  };

  const handlePostToChange = (e) => {
    const { name } = e.target;
    setPostTo((inputs) => ({
      ...inputs,
      [name]: { ...inputs[name], selected: !postTo[name].selected },
    }));
  };



  const handleScheduleDate = (date, platform) => {
    setPostTo((inputs) => ({
      ...inputs,
      [platform]: { ...inputs[platform], scheduleDate: date },
    }));
  };

  const handleText = (e) => {
    const { name, value } = e.target;
    setPostTo((inputs) => ({
      ...inputs,
      [name]: { ...inputs[name], text: value },
    }));
  };

  const isPlatformHidden = (p) => {
    if (p === "facebook" && openPlatform === "facebook") return true
    if (p === "twitter" && openPlatform === "twitter") return true
    return false
  }

  return (
    <div>
      <div className="header">
        <h1>פרסום</h1>
      </div>
      <div className="body">
        <div className="post-to">
          <h3>פרסום אל</h3>
          <div className="checkbox-label-container">
            <input
              type="checkbox"
              checked={postTo.facebook.selected}
              name="facebook"
              onChange={handlePostToChange}
              disabled={loadedArticle?.postedTo?.facebook}
              hidden={isPlatformHidden("twitter")}
            />
            <label hidden={isPlatformHidden("twitter")} className="checkbox-label">פייסבוק</label>
          </div>
          <div className="checkbox-label-container">
            <input
              type="checkbox"
              checked={postTo.twitter.selected}
              name="twitter"
              onChange={handlePostToChange}
              disabled={loadedArticle?.postedTo?.twitter}
              hidden={isPlatformHidden("facebook")} 
            />
            <label hidden={isPlatformHidden("facebook")} className="checkbox-label">טוויטר</label>
          </div>
        </div>
        <AttachmentSelection initialValue={loadedArticle?.attachment || null} onSelectionChanged={(name) => {
          setSelectedAttachment(name)
        }}/>

        <Platform
          platformText="פייסבוק"
          disabled={!postTo.facebook.selected}
          platformValue="facebook"
          updateScheduleDate={handleScheduleDate}
          updateText={handleText}
          text={postTo.facebook.text}
          getArticleSchedule={postTo.facebook.scheduleDate}
          hidden={isPlatformHidden("twitter")} 

        />
        <Platform
          platformText="טוויטר"
          disabled={!postTo.twitter.selected}
          updateScheduleDate={handleScheduleDate}
          updateText={handleText}
          text={postTo.twitter.text}
          platformValue="twitter"
          getArticleSchedule={postTo.twitter.scheduleDate}
          hidden={isPlatformHidden("facebook")} 

        />

        <div className="actions">
          <button disabled={isLoading} onClick={postArticle}>
            {isLoading ? <i className="fa fa-circle-o-notch fa-spin"></i> : "פרסום"}
          </button>
        </div>
      </div>
    </div>
  );
};


export default Post

//export default Post;
