import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

import DatePicker from "react-datepicker";
import he from "date-fns/locale/he";

const Platform = (props) => {
  
    const [scheduleDate, setScheduleDate] = useState(
      new Date()
    );
    const [isMinimized,setIsMinimized] = useState(props.minimized || false)
    const [isScheduled, setIsScheduled] = useState(null);

      const toogleMinimized =( ) => {
        setIsMinimized(isMinimized => !isMinimized)
      }

    return (
      <div hidden={props.hidden} className={props.disabled ? "platform disabled" : "platform"}>
        <div className="top-container">
          <h3>{props.platformText}</h3>
          <i onClick={toogleMinimized} className={isMinimized? "fa fa-angle-double-down": "fa fa-angle-double-up"}/>
        </div>
        <div className={isMinimized? "minimized": ""} >
        <div className="schedule">
          <div className="checkbox-label-container">
            <input
              type="checkbox"
              defaultChecked={isScheduled}
              onChange={() => {
                setIsScheduled(!isScheduled);
                if (isScheduled){ props.updateScheduleDate(null,props.platformValue); return;}
                
                props.updateScheduleDate(scheduleDate,props.platformValue)
              }}
              disabled={false}
            />
            <label className="checkbox-label">מתוזמן</label>
          </div>
          <div className="picker-container">
            <DatePicker
              selected={scheduleDate}
              onChange={(date) => {
                setScheduleDate(date);
                props.updateScheduleDate(date, props.platformValue);
              }}
              timeInputLabel="שעה: "
              minTime={new Date()}
              minDate={new Date()}
              dateFormat="dd/MM/yy HH:mm"
              locale={he}
              disabled={!isScheduled}
              showTimeInput
            />
          </div>
        </div>
        <textarea
          rows="4"
          name={props.platformValue}
          value={props.text}
          onChange={props.updateText}
        ></textarea>
        </div>
      </div>
    );
  };

  export default Platform