import React, { useState } from "react";

const DisplayBy = (props) => {
  const [selected, setSelected] = useState("1");

  const handleChange = (e) => {
    const id = e.target.id;
    setSelected(id);
    props.onSelectionChanged(id);
  };

  return (
    <div className="container">
      <div className="display-by-options">
        <button onClick={handleChange} id="1" className={selected === "1" ? "selected" : null}>
          ממתינים
        </button>
        <button onClick={handleChange} id="2" className={selected === "2" ? "selected" : null}>
          פורסמו
        </button>
        <button onClick={handleChange} id="3" className={selected === "3" ? "selected" : null}>
          הכל
        </button>
      </div>
      <span className="count">{props.count}</span>
    </div>
  );
};

export default DisplayBy;
