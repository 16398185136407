import React, { useState } from "react";
import { Redirect } from "react-router";
import { appName } from "../../utils";

const Login = (props) => {
  const [inputs, setInputs] = useState({ username: "", password: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [formMessage, setFormMessage] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!inputs.username || !inputs.password) {
      alert("יש למלא את שם המשתמש והסיסמא");
      return;
    }

    const handleResponse = (response) => {
      setIsLoading(false);
      if (!response.ok) {
        setFormMessage("pw");
        return;
      }
      const token = response.token;
      localStorage.setItem("token", token);
      setFormMessage("ok");
    };

    setIsLoading(true);
    fetch("/api/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(inputs),
    })
      .then(
        (res) => res.json()
        // if (!res.ok) {
        //   throw Error(res.statusText);
        // }
      )
      .then(
        (result) => {
          handleResponse(result);
        },
        (error) => {
          setIsLoading(false);
          setFormMessage("err");
        }
      );
  };

  const renderFormMessage = (formMessage) => {
    switch (formMessage) {
      case "pw":
        return "שם המשתמש או הסיסמא שגויים";
      case "err":
        return "שגיאה באימות הנתונים, יש לפנות למנהל";
      case "ok":
        return <Redirect to="/" />;
      default:
        return null;
    }
  };

  return (
    <div className="login-container">
      <form className="login" onSubmit={handleSubmit}>
        <div className="form-header">
          <h3>{appName}</h3>
          <p>אדמין הפצות</p>
        </div>

        <div className="form-group">
          <h4>שם משתמש</h4>
          <input
            type="text"
            placeholder="הזן שם משתמש"
            name="username"
            required
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <h4>סיסמא</h4>
          <input
            type="password"
            placeholder="הזן סיסמא"
            name="password"
            required
            onChange={handleInputChange}
          />
        </div>
        {formMessage ? (
          <div className="form-group">
            <h4 className="message">{renderFormMessage(formMessage)}</h4>
          </div>
        ) : null}

        <div className="form-group">
          <button type="submit" disabled={isLoading}>
            {isLoading ? <i className="fa fa-circle-o-notch fa-spin"></i> : <p>התחברות</p>}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
