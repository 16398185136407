import React, { useState, useEffect } from "react";
import DisplayBy from "../display-by";
import Articles from "../articles";
import Popup from "../popup";
import Post from "../popup/post";
import Push from "../popup/push";
import { useHistory } from "react-router-dom";

import {
  useParams
} from "react-router-dom";
import decode from "jwt-decode";
import Whatsapp from "../popup/whatsapp";
import { isKore } from "../../utils";

const Main = (props) => {
  const history = useHistory();

  const getUserPermission = () => {
    const token = localStorage.getItem("token");
    if (!token) return ;
    try {
      const {permission} = decode(token);
      console.log("user has "+permission + " permission");
      return permission
    }catch(e){
      console.log("error decoding token: ",e)
  }
  }
  const [selectPopupOpen, setSelectPopupOpen] = useState(false);

  const [articles, setArticles] = useState([]);
  const [initialLoading, setInitialLoading] = useState(false);
  const [selectedDisplay, setSelectedDisplay] = useState("1");
  const [newPopup, setNewPopup] = useState(false); //store popup type. 0 = closed

  // eslint-disable-next-line
  const [userPermission, setUserPermission] = useState(() => getUserPermission())

  const initEventSource = () => {
      const devprefix = window.location.host.includes("localhost:") ? "http://localhost:5000" : ""
      const articlesStream = new EventSource(`${devprefix}/api/articles/stream?token=${localStorage.getItem("token")}`)
      let keepaliveTimer = null;
      const restartIfInactiveFor = 65
      
      articlesStream.addEventListener('initial', (e) => {
        setArticles(JSON.parse(e.data));
        setInitialLoading(false);
      });

      articlesStream.addEventListener('new', (e) => {
        const article = JSON.parse(e.data)
        console.log("new article from stream",article)
        updateArticle(article)
      });


      articlesStream.addEventListener('update', ((e) => {
        const article = JSON.parse(e.data)
        console.log("update article from stream",article)
        updateArticle(article)
      }));

      articlesStream.addEventListener('delete', ((e) => {
        const {id} = JSON.parse(e.data)
        console.log("delete article from stream, id: ",id)
        removeFromArticles(id)
      }));
      articlesStream.onerror = error =>  {
        console.log("articlesStream error closing and reopening stream")
        if (keepaliveTimer != null) {
          clearTimeout(keepaliveTimer);
        }
        
        keepaliveTimer = setTimeout(() => {
          console.log("stream reopen error")
          articlesStream.close()

          initEventSource()
        }, 2000);
      }

      articlesStream.addEventListener('keep-alive', ((e) => {
        console.log("keep-alive event")
        if (keepaliveTimer != null) {
          clearTimeout(keepaliveTimer);
        }
        keepaliveTimer = setTimeout(() => {
           console.log("stream reopen keep-alive timeout" )

          articlesStream.close()
          initEventSource()

        }, restartIfInactiveFor * 1000);
      }));
      
    }
  let { openPlatform } = useParams();

  useEffect(() => {
    const checkParams = async () => {

      if (openPlatform === "whatsapp"){
        console.log("whatsapp")
        setNewPopup(3)
      }

      switch (openPlatform) {
        case "whatsapp":
          console.log("whatsapp in url")
          if ((userPermission !== "admin" && userPermission !== "push")) return
          setNewPopup(3)
          break;

        case "twitter":
            console.log("twitter in url")
            if ((userPermission !== "admin" && userPermission !== "post")) return
            setNewPopup(1)
            break;

        case "facebook":
          console.log("facebook in url")
          if ((userPermission !== "admin" && userPermission !== "post")) return
          setNewPopup(1)
          break;


        case "push":
          console.log("push in url")
          if ((userPermission !== "admin" && userPermission !== "push")) return
          setNewPopup(2)
          break; 

        case "push-mobile":
            console.log("push mobile in url")
            if ((userPermission !== "admin" && userPermission !== "push")) return
            setNewPopup(2)
            break; 

        case "push-desktop":
              console.log("push desktop in url")
              if ((userPermission !== "admin" && userPermission !== "push")) return
              setNewPopup(2)
              break; 
        default:
          break;
      }
      
    }
    initEventSource();
    checkParams()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeFromArticles = (articleID) => {
    console.log("remove");
    console.log(articleID);
    setArticles(articles => articles.filter((v) => v.id !== articleID));
  };

  const updateArticle = (article) => {
    setArticles( (articles) =>{
      // article in articles list
      let match = false
      const mathResult = articles.map((a) => {
        if (a.id === article.id){
          match = true
          return article
        };
        return a;
      })
      if (match) return mathResult

      //add to list if not in allready
      const MAX_COUNT = 100
      articles.push(article)
      articles = articles.sort((a, b) => (a.updatedAt > b.updatedAt ? 1 : -1))
      if (articles.length > MAX_COUNT){
        console.log("slice")
        articles = articles.slice(Math.max(articles.length - MAX_COUNT, 1))
      }
      return [...articles]
    }
    );
  };

  const filterArticlesByDisplay = () => {
    const filterByPosted = (v, f) => {
      if (userPermission === "admin") return f ? (!v.postedTo.facebook || !v.postedTo.twitter || !v.postedTo.whatsapp || !v.pushedTo.oneSignalMobile || !v.pushedTo.oneSignalDesktop) : (v.postedTo.facebook && v.postedTo.twitter && v.postedTo.whatsapp && v.pushedTo.oneSignalMobile && v.pushedTo.oneSignalDesktop)
      if (userPermission === "post") return f ? (!v.postedTo.facebook || !v.postedTo.twitter) : (v.postedTo.facebook && v.postedTo.twitter)
      if (userPermission === "push" && isKore) return f ? ( !v.postedTo.whatsapp   || !v.pushedTo.oneSignalDesktop ) : (v.postedTo.whatsapp && v.pushedTo.oneSignalDesktop)

      if (userPermission === "push") return f ? (!v.postedTo.facebook || !v.postedTo.whatsapp || !v.pushedTo.oneSignalMobile || !v.pushedTo.oneSignalDesktop) : (v.postedTo.facebook && v.postedTo.whatsapp && v.pushedTo.oneSignalMobile && v.pushedTo.oneSignalDesktop )
    }
    switch (selectedDisplay) {
      case "1":
        return articles.filter((v) => filterByPosted(v,true));
      case "2":
        return articles.filter((v) => filterByPosted(v,false));
      case "3":
        return articles;
      default:
        return null;
    }
  };

  const handleNewBtn = () => {
    if (userPermission === "admin" || userPermission === "push"){
      setSelectPopupOpen((v) => !v)
      return
    }
    if (userPermission === "post") setNewPopup(1)
    if (userPermission === "push") setNewPopup(2)
  }
  const handleNewOptionBtn = (type) => {
    setNewPopup(type)
    setSelectPopupOpen(false)
  }
  const dismissPopup = () => {
    history.push("/");
    setNewPopup(false)
  }

  const renderPopup = () => {
    switch (newPopup) {
      case 1:
        return (<Post
        change={{
          removeFromArticles: removeFromArticles,
          closePopup: () => dismissPopup(),
          updateArticle: updateArticle

        }}
        />)
      case 2:
        return (<Push
          change={{
            removeFromArticles: removeFromArticles,
            closePopup: () => dismissPopup(),
            updateArticle: updateArticle

          }}
          />)
      case 3:
            return (<Whatsapp
              change={{
                removeFromArticles: removeFromArticles,
                closePopup:  () => dismissPopup(),
                updateArticle: updateArticle

              }}
              />)    
      default:
        return null;
    }
  }
  function componentsJoin(components, separator) {
    return components.reduce(
      (acc, curr) => (acc.length ? [...acc, separator, curr] : [curr]),
      []
    );
  }
  const renderSelectPopupOptions = (len) =>{
    const devider =  <div className="devider"></div> 
    const options = []
    if (userPermission === "admin" || userPermission === "post"){
      options.push(<div className="option" onClick={() => handleNewOptionBtn(1)}><i className="fa fa-facebook"/>  <i className="fa fa-twitter"/></div>)
    }      
    if (userPermission === "admin" || userPermission === "push"){
      //if (!isKore)
        options.push(<div className="option" onClick={() => handleNewOptionBtn(2)}><i className="fa fa-bell"/></div>)
      options.push(<div className="option" onClick={() => handleNewOptionBtn(3)}><i className="fa fa-whatsapp"/></div>)

    }        

    if (len) return options.length
    return componentsJoin(options,devider)
    
  }
  return (
    <div>
      <div className="top-section-container">
        <div className="top-bar">
          <h4>אדמין הפצות</h4>
        </div>

        <div className="display-by">
          <h3 className="title">הצג לפי</h3>
          <DisplayBy
            onSelectionChanged={(v) => setSelectedDisplay(v)}
            count={filterArticlesByDisplay().length}
          />
        </div>
      </div>
      {
          selectPopupOpen && (
            <div className="float-new-btn selection-container fade-in" style={{height: 35.7 * renderSelectPopupOptions(true) + "px"}}>
            <div className="inner-container" >
            {renderSelectPopupOptions()}
            </div>
          </div>
          )
        }
      <span
        className="float-new-btn pointer"
        onClick={handleNewBtn}
      >


        <i className={selectPopupOpen ? "fa fa-minus" : "fa fa-plus"}></i>
      </span>
      {newPopup && (
        <Popup closePopup={ dismissPopup}>
        {renderPopup()}
        </Popup>
      )}
      {initialLoading ? (
        <h2 className="loading">טוען...</h2>
      ) : (
        <Articles
          removeFromArticles={removeFromArticles}
          updateArticle={updateArticle}
          articles={filterArticlesByDisplay().sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1))}
          userPermission={userPermission}
          displayBy={selectedDisplay}
        />
      )}
      <span className="logout" onClick={() => {
        if (window.confirm("האם אתה בטוח שברצונך להתנתק מהאתר?")){
          localStorage.clear();
          window.location.reload();

        }
      }}>התנתק</span>
    </div>
  );
};

export default Main;
