import React from "react";
const Popup = (props) => {
  return (
    <div className="popup-container fade-in">
      <div className="popup">
        <div className="close" onClick={props.closePopup}>
          x
        </div>
        <div className="content">{props.children}</div>
      </div>
    </div>
  );
};

export default Popup;
