import React from "react";
import Article from "./article";
const Articles = (props) => {
  return (
    <div className="articles">
      {props.articles.length !== 0 ? (
        props.articles.map((article) => {
          return (
            <Article
              key={article.id}
              id={article.id}
              text={article.text}
              image={article.image}
              updateArticle={props.updateArticle}
              userPermission={props.userPermission}
              displayBy={props.displayBy}
              attachment={article.attachment}
              pushedTo={article.pushedTo}
              postedTo={article.postedTo}
            />
          );
        })
      ) : (
        <h4 className="no-items">אין כתבות</h4>
      )}
    </div>
  );
};

export default Articles;
